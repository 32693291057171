var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBLBASEINFO" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-appr-btn", {
                        ref: "appr-btn",
                        attrs: {
                          editable: _vm.editable,
                          approvalInfo: _vm.approvalInfo,
                        },
                        on: {
                          beforeApprAction: _vm.approvalBudgetPlan,
                          callbackApprAction: _vm.approvalBudgetPlanCallback,
                          requestAfterAction: _vm.getDetail,
                        },
                      }),
                      _vm.editable && !_vm.disabled && _vm.isOld
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "delete" },
                            on: { btnClicked: _vm.deleteData },
                          })
                        : _vm._e(),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled,
                            expression: "editable && !disabled",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.budgetPlan,
                          mappingType: _vm.mappingType,
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveData,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: "",
                        disabled: _vm.disabled || _vm.isOld,
                        editable: _vm.editable,
                        name: "plantCd",
                      },
                      on: { datachange: _vm.getItems },
                      model: {
                        value: _vm.budgetPlan.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.budgetPlan, "plantCd", $$v)
                        },
                        expression: "budgetPlan.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: "",
                        disabled: _vm.disabled || _vm.isOld,
                        editable: _vm.editable,
                        label: "LBL0010320",
                        name: "budgetYear",
                        type: "year",
                        default: "today",
                      },
                      model: {
                        value: _vm.budgetPlan.budgetYear,
                        callback: function ($$v) {
                          _vm.$set(_vm.budgetPlan, "budgetYear", $$v)
                        },
                        expression: "budgetPlan.budgetYear",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        type: "number",
                        suffix: "원",
                        label: "LBL0010324",
                        name: "budgetAllAmt",
                      },
                      model: {
                        value: _vm.budgetPlan.budgetAllAmt,
                        callback: function ($$v) {
                          _vm.$set(_vm.budgetPlan, "budgetAllAmt", $$v)
                        },
                        expression: "budgetPlan.budgetAllAmt",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-table",
            {
              ref: "table",
              staticClass: "q-mt-sm",
              attrs: {
                title: "LBL0010325",
                checkboxDisabled: "checkboxDisabled",
                columns: _vm.grid.columns,
                data: _vm.budgetPlan.planItems,
                gridHeight: _vm.grid.height,
                editable: _vm.editable && !_vm.disabled,
                columnSetting: false,
                expandAll: false,
                isFullScreen: false,
                usePaging: false,
                hideBottom: true,
                filtering: false,
                rowKey: "budgetYearPlanItemId",
                selection: "multiple",
              },
              on: { "table-data-change": _vm.tableDataChange },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "budgetItemName"
                        ? [
                            props.row.imprFlag !== "Y"
                              ? _c("c-text-column", {
                                  attrs: {
                                    budgetItemName: "",
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    col: col,
                                    props: props,
                                  },
                                  on: {
                                    datachange: function ($event) {
                                      return _vm.datachange(props)
                                    },
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " + _vm._s(props.row[col.name]) + " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "직접추가", icon: "add" },
                            on: { btnClicked: _vm.addItemKeyin },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                            on: { btnClicked: _vm.addItem },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "제외", icon: "remove" },
                            on: { btnClicked: _vm.deleteItem },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }